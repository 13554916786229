@use "../abstracts/variables" as vars;
@use "../abstracts/mixins" as mix;
@use "../abstracts/functions" as func;
@use "sass:map";

label{
    &.lightLabel{
        color: map.get(vars.$colors, background);
        font-weight: 600;
    }
    &.transparentLabel{
        color: map.get(vars.$colors, background);
        opacity: 0.5;
        font-size: 12px;
        font-weight: 600;
    }
}

input, textarea{
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 0 20px 0 25px;
    height: 50px;
    font-size: func.pxtorem(16);
    margin: 5px 0;
    &::placeholder{
        color: map.get(vars.$colors, primaryButton);
        font-size: 14px;
    }
    &.darkInput{
        background-color: map.get(vars.$colors, inputSign);
        color: map.get(vars.$colors, white);
    }
    &.lightInput{
        background-color: map.get(vars.$colors, inputEvent);
        color: map.get(vars.$colors, black);
        margin-bottom: 15px;
        width: 320px;
        &--check{
            margin-right: 10px;
            width: 15px;
        }
    }
    &.transparentInput{
        background: transparent;
        border: none;
        border-bottom: 1px solid map.get(vars.$colors, primaryButton);
        border-radius: 0;
        padding: 0px;
        margin-bottom: 35px;
        margin-top: -10px;
        outline: none;
        color: map.get(vars.$colors, background);
        font-weight: 600;
        &:focus{
            background-color: transparent;
        }
    }
}

textarea{
    height: 80px;
    padding: 15px 20px 0 25px;
}

input, label{
    display: block;
}

input:focus, textarea:focus{
    background-color: map.get(vars.$colors, inputActive);
    color: map.get(vars.$colors, black);
}

.errorContainer{
    color: map.get(vars.$colors, redSos);
}

.uploaded{
    color: map.get(vars.$colors, white);
    font-weight: 600;
}

.uploadedBlack{
    color: map.get(vars.$colors, black);
    font-weight: 600;
}
@use "../abstracts/variables" as vars;
@use "../abstracts/mixins" as mix;
@use "../abstracts/functions" as func;
@use "../abstracts/extends";
@use "../abstracts/mediamixins" as media;
@use "sass:map";

.header{
    position: relative;
    margin: 0;
    z-index: -2;
    &__banner{
        width: 100%;
        height: 200px;
        opacity: .5;
        object-fit: cover;
    }
    &__logo{
        width: 70%;
        border-radius: 80%;
        background-color: map.get(vars.$colors, background);
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.authenticationContainer{
    height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    &--forgotPassword{
        height: calc(100vh - 300px);
    }
    .authentication{
        background-size: 100%;
        display: flex;
        flex-direction: column;
        width: 300px;
        margin: auto;
        &__header{
            text-transform: uppercase;
            font-weight: 600;
            font-size: 18px;
            color: map.get(vars.$colors, white);
        }
        &__forgot{
            align-self: flex-end;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            color: map.get(vars.$colors, white);
            background: transparent;
            padding: 0;
            margin: -10px 0;
        }
        &__line{
            width: 100%;
            height: 15px; 
            margin-top: 30px;
            margin-bottom: 40px;
            border-bottom: 1px solid map.get(vars.$colors, white);
            text-align: center;
            span{
                font-size: 18px; 
                background-color: map.get(vars.$colors, background);
                color: map.get(vars.$colors, white);
                padding: 0 10px;
            }
        }
        &__sign{
            color: map.get(vars.$colors, white);
            text-align: center;
            margin-top: 30px;
            span{
                color: map.get(vars.$colors, linkBlue);
            }
            &--forgotPassword{
                margin-top: 10px;
            }
        }
    }
}
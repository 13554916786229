@use "../abstracts/variables" as vars;
@use "sass:map";

@mixin circle($width, $color){
    display: flex;
	justify-content: center;
	align-items: center;
    padding: 0;
    height: $width;
    width: $width;
    background-color: $color;
	border-radius: 50%;
}

@mixin rectangle($width, $opacity, $color, $line){
    display: flex;
	justify-content: center;
	align-items: center;
    padding: 0;
    height: $width;
    width: $width;
    opacity: $opacity;
    background-color: $color;
	border-radius: 10%;
    border: 1px $line map.get(vars.$colors, white);
}

@mixin line ($thickness, $color){
    border: none;
    border-top: $thickness solid $color;
}
@use "../abstracts/variables" as vars;
@use "../abstracts/mixins" as mix;
@use "../abstracts/functions" as func;
@use "../abstracts/extends";
@use "../abstracts/mediamixins" as media;
@use "sass:map";

// settings page
.settings{
    background-color: map.get(vars.$colors, white);
    color: map.get(vars.$colors, background);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    .profile{
        @extend %flex-centered-column;
        &__img{
            object-fit: cover;
            width: 130px;
            height: 130px;
            border-radius: 50%;
        }
        &__name{
            font-weight: 600;
            margin-bottom: -5px;
        }
        &__username{
            font-size: 12px;
            opacity: 0.5;
        }
    }
    .accountSettings{
        @extend %flex-margin-column;
        margin-top: 50px;
        &__title{
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 20px;
        }
    }
}

// edit profile page
.profileData{
    &__icon{
        color: map.get(vars.$colors, background);
        position: absolute;
        top: 10%;
        left: 5%;
        font-size: 30px;
    }
}

.profileEdit{
    @extend %flex-margin-column;
    width: 300px;
    padding-top: 20px;
    margin-bottom: 120px;
    &__img{
        object-fit: cover;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin: 0 auto 30px auto;
    }
    &__email{
        color: map.get(vars.$colors, background);
        margin-bottom: 40px;
        font-weight: 600;
    }
}
@use "../abstracts/variables" as vars;
@use "../abstracts/mixins" as mix;
@use "../abstracts/functions" as func;
@use "../abstracts/extends";
@use "../abstracts/mediamixins" as media;
@use "sass:map";

.newEvent{
    padding-bottom: 85px;
    &__import{
        @extend %flex-centered-column;
        margin-top: 10px;
        margin-bottom: 20px;
        height: 160px;
        background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url("https://images.pexels.com/photos/4457409/pexels-photo-4457409.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
        background-position: center;
        background-size: cover;
        color: map.get(vars.$colors, white);
        i{
            @include mix.rectangle(50px, 0.35, map.get(vars.$colors, black), dashed);
            font-size: 25px;
            color: map.get(vars.$colors, primaryButton);
        }
    }
    &__file{
        display: none;
    }
    &__formAdd{
        @extend %flex-margin-column;
        width: 320px;
        margin-top: 10px;
    }
    &__addButton{
        margin-top: 10px;
        margin-bottom: 25px;
    }
}
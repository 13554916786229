@use "../abstracts/variables" as vars;
@use "../abstracts/mixins" as mix;
@use "sass:map";

button{
    border: none;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    transition: all .2s ease-in-out;
    text-align: center;
    width: auto;
    height: 50px;
    &.primary{
        background-color: map.get(vars.$colors, primaryButton);
        color: map.get(vars.$colors, background);
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 20px;
        padding: 0 50px;
    }
    &.secondary{
        background-color: map.get(vars.$colors, lightblue);
        color: map.get(vars.$colors, white);   
        text-transform: lowercase; 
        margin: 20px 0;
        padding: 0 50px;
    }
    &.account{
        background-color: map.get(vars.$colors, accountButton);
        color: map.get(vars.$colors, background);
        display: flex;
        align-items: center;
        padding: 30px 20px;
        text-align: left;
        text-transform: none;
        margin-bottom: 30px;
        position: relative;
        width: 320px;
        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.2));
        .circle{
            color: map.get(vars.$colors, white);
            margin-right: 10px;
            font-size: 15px;
        }
        .profile{
            @include mix.circle(35px, map.get(vars.$colors, profileSettings));
        }
        .delete{
            @include mix.circle(35px, map.get(vars.$colors, redDelete));
        }
        .signout{
            @include mix.circle(35px, map.get(vars.$colors, signoutSettings));
        }
        .right{
            position: absolute;
            top: 35%;
            right: 10%;
        }
    }
}


@use "../abstracts/variables" as vars;
@use "../abstracts/mixins" as mix;
@use "../abstracts/functions" as func;
@use "../abstracts/extends";
@use "../abstracts/mediamixins" as media;
@use "sass:map";

.dashboard{
    background-color: map.get(vars.$colors, white);
    padding-bottom: 80px;
    &__links{
        display: flex;
        justify-content: space-around;
        font-weight: 600;
        padding-top: 10px;
        color: map.get(vars.$colors, background);
    }
    &__events{
        color: map.get(vars.$colors, background);
        background-color: transparent;
        text-transform: uppercase;
        border-radius: 0;
        height: 30px;
        opacity: 0.5;
        font-weight: 600;
        &--active{
            border-bottom: 1px solid map.get(vars.$colors, background);
            opacity: 1;
        }
    }
    .event{
        &__item{
            margin: 30px auto;
        }
    }
}
$colors: (
    white: #FFFFFF,
    black: #000000,
    background: #093545,
    lightblue: #00C6AB,
    primaryButton: #B5C2C7,
    inputSign: #375B68,
    inputActive: #E8F0FE,
    inputEvent: #F5F6FB,
    redSos: #BB4444,
    redDelete: #FF6868,
    facebook: #415792,
    twitter: #49A1E9,
    google: #D94F43,
    linkBlue: #4778FF,
    accountButton: #F6F6F6,
    dateBackground: #434343,
    profileSettings: #8D8D8D,
    signoutSettings: #3191FF
);

$text-font: 'Montserrat', sans-serif;

$base-fontsize: 100%;

$h1: 3.052rem;
$h2: 2.441rem;
$h3: 1.953rem;
$h4: 1.563rem;
$h5: 1.25rem;
$smallText: 0.8rem;

$small-width: 768px;
$large-width: 1024px;
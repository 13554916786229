@use "../abstracts/variables" as vars;
@use "sass:map";

%background-white-rounded{
    height: 90vh;
    background-color: map.get(vars.$colors, white);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

%flex-centered-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

%flex-margin-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}
@use "../abstracts/variables" as vars;
@use "sass:map";

*,
*:after,
*::before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body{
    background-color: map.get(vars.$colors, white);
    color: map.get(vars.$colors, black);
}

a{
    text-decoration: none;
}
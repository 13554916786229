@use "../abstracts/variables" as vars;
@use "sass:map";

html {
    font-size: vars.$base-fontsize;
} 

body {
    font-family: vars.$text-font;
    font-weight: 400;
    line-height: 1.75;
}

h1, h2, h3, h4, h5 {
    font-weight: 400;
    line-height: 1.3;
}

h1 {
    font-size: vars.$h1;
}

h2 {
    font-size: vars.$h2;
}

h3 {
    font-size: vars.$h3;
}

h4 {
    font-size: vars.$h4;
}

h5 {
    font-size: vars.$h5;
}

small, .text_small {
    font-size: vars.$smallText;
}

.title{
    position: relative;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    padding: 20px;
    width: 100%;
    margin: 0;
    background-color: map.get(vars.$colors, background);
    color: map.get(vars.$colors, white);
    z-index: 10;
}
@use "../abstracts/variables" as vars;
@use "../abstracts/mixins" as mix;
@use "../abstracts/functions" as func;
@use "../abstracts/extends";
@use "../abstracts/mediamixins" as media;
@use "sass:map";

.eventInd{
    &__icon{
        border: 1px solid white;
        border-radius: 10px;
        padding: 10px;
        color: map.get(vars.$colors, white);
        position: absolute;
        top: 10%;
        left: 5%;
        font-size: 15px;
    }
    &__img{
        width: 100%;
        height: 228px;
        object-fit: cover;
    }
    &__container{
        max-width: 320px;
        margin: auto;
    }
    &__header{
        color: map.get(vars.$colors, black);
        font-size: 16px;
        font-weight: 600;
        margin-top: 20px;
        &--second{
            margin-bottom: 0px;
        }
    }
    &__paragraph{
        color: map.get(vars.$colors, profileSettings);
        padding: 15px 20px;
        font-size: 14px;
        i{
            margin-right: 10px;
        }
    }
    &__line{
        border: none;
        border-top: 1px solid map.get(vars.$colors, profileSettings);
        width: 100%;
    }
}
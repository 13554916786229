@use "../abstracts/variables" as vars;
@use "../abstracts/mixins" as mix;
@use "../abstracts/functions" as func;
@use "../abstracts/extends";
@use "../abstracts/mediamixins" as media;
@use "sass:map";

.notification{
    padding: 20px 0;
    @extend %flex-centered-column;
}
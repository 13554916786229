@use "../abstracts/variables" as vars;
@use "../abstracts/mixins" as mix;
@use "../abstracts/functions" as func;
@use "../abstracts/extends";
@use "../abstracts/mediamixins" as media;
@use "sass:map";

.meldet{
    &__formAdd{
        padding: 20px 0;
        @extend %flex-margin-column;
        width: 320px;
        margin-top: 10px;
        margin-bottom: 70px;
    }
    &__checkContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}
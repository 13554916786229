@use "../abstracts/variables" as vars;
@use "../abstracts/mixins" as mix;
@use "sass:map";

.socials{
    display: flex;
    align-items: center;
    justify-content: space-around;
    &__ind{
        @include mix.circle(50px, black);
        i{
            font-size: 25px;
            color: white;
        }
        &--facebook{
            background-color: map.get(vars.$colors, facebook);
        }
        &--twitter{
            background-color: map.get(vars.$colors, twitter);
        }
        &--google{
            background-color: map.get(vars.$colors, google);
        }
    }
}

.avatar{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5px;
    &--profile{
        margin-bottom: 40px;
    }
    &__file{
        display: none;
    }
    i{
        @include mix.circle(90px, map.get(vars.$colors, inputSign));
        font-size: 35px;
        color: map.get(vars.$colors, primaryButton);
    }
    &__text{
        color: map.get(vars.$colors, primaryButton);
        font-weight: 600;
    }
}
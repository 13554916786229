@use "../abstracts/variables" as vars;
@use "../abstracts/mixins" as mix;
@use "../abstracts/functions" as func;
@use "../abstracts/extends";
@use "../abstracts/mediamixins" as media;
@use "sass:map";

.nav{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: map.get(vars.$colors,background);
    z-index: 5;
    &__list{
        list-style: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 85px;
    }
    &__link{
        background-color: transparent;
        color: map.get(vars.$colors, white);
        padding: 0;
        font-size: 20px;
        &--active{
            color: map.get(vars.$colors, lightblue);
            border-bottom: 1px solid map.get(vars.$colors, lightblue);
            height: 30px;
            border-radius: 0;
        }
        &--add{
            color: map.get(vars.$colors, lightblue);
        }
        &--large{
            font-size: 30px;
        }
    }
}
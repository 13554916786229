@use "../abstracts/variables" as vars;
@use "../abstracts/mixins" as mix;
@use "../abstracts/functions" as func;
@use "sass:map";

.event{
    &__item{
        position: relative;
        text-align: center;
        width: 322px;
        filter: drop-shadow(0px 10px 10px map.get(vars.$colors, profileSettings));
        color: map.get(vars.$colors, white);
    }
    &__date{
        position: absolute;
        top: -10px;
        right: -10px;
        @include mix.circle(50px, map.get(vars.$colors, lightblue));
        font-weight: 600;
        text-transform: uppercase;
        font-size: func.pxtorem(14);
    }
    &__img{
        width: 322px;
        border-radius: 10px;
        height: 176px;
        object-fit: cover;
    }
    &__title{
        font-weight: 600;
        padding: 5px 15px;
        text-transform: uppercase;
        font-size: func.pxtorem(16);
        position: absolute;
        bottom: 8px;
        left: 0;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: map.get(vars.$colors, lightblue);
    }
}

.notification{
    margin-top: 20px;
    &__item{
        position: relative;
        width: 322px;
        padding: 5px 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        background: linear-gradient(to right, map.get(vars.$colors, lightblue) 0%, map.get(vars.$colors, lightblue) 20%, map.get(vars.$colors, white) 20%, map.get(vars.$colors, white) 100%);
        color: map.get(vars.$colors, background);
        border-radius: 10px;
        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.2));
    }
    &__delete{
        position: absolute;
        top: -10px;
        left: -10px;
        @include mix.circle(30px, map.get(vars.$colors, redDelete));
        color: map.get(vars.$colors, white);
        cursor: pointer;
    }
    &__date{
        align-items: center;
        height: 100%;
        color: map.get(vars.$colors, white);
    }
    &__info{
        display: flex;
        flex-direction: column;
        font-size: func.pxtorem(14);
        margin-right: 40px;
    }
    &__name{
        font-weight: 600;
        margin-bottom: 10px;
    }
    &__accept{
        i{
            font-size: 30px;
            color: map.get(vars.$colors, lightblue);
            cursor: pointer;
        }
    }
}